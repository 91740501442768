import React, { useEffect, useState } from "react";
import { loaderVisibility } from "@lib/store.ts";
import { useStore } from "@nanostores/react";
import { LinearProgress,  } from "@mui/material";
import Box from "@components/ui/box.astro";

export const PageLoader = () => {
const { isLoaderVisible } = useStore(loaderVisibility);

  useEffect(() => {
    loaderVisibility.set({isLoaderVisible:false})
    const links= document.querySelectorAll('a.page-loader')
    links.forEach((link) => {
      link.addEventListener("click", (event) => {
        const targetPathname = new URL(event?.currentTarget?.href).pathname;
        if(window.location.pathname !== targetPathname){
          loaderVisibility.set({isLoaderVisible:true})
        }
      });
    });
    return ()=>{
      links.forEach((link) => {
        link.removeEventListener("click", () => {
          console.log("clicking the links");
        });
      });
    }
  }, []);
  return (
    <>
      {isLoaderVisible && (
        <span className="block h-1 w-full page-loader-skeleton"></span>
      )}
    </>
  );
};
