---
const { class: className } = Astro.props;
---

<div
  class:list={[
    " rounded-2xl relative pr-8 bg-gray-50/60 backdrop-blur-md transition-all ring-1 ring-gray-200/50 shadow-lg md:shadow hover:shadow-lg",
    className,
  ]}>
  <slot />
</div>
